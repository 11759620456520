<template>
  <div class="main-conent main-conent-minheight form-footer">

    <page-header into="">
      <strong>{{ruleForm.id>0?'编辑管理员':'新增管理员'}}</strong>
    </page-header>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card shadow="never" class="border-none margin-t24">
        <div slot="header">
          <span></span>
        </div>
        <el-row :gutter="24">
          <el-col>
            <el-form-item label="账号" prop="userName">
              <el-input v-model="ruleForm.userName"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="ruleForm.password"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="昵称" prop="nickName">
              <el-input v-model="ruleForm.nickName"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="是否启用" prop="isLock">
              <el-switch style="display: block" v-model="ruleForm.isLock" active-color="#ff4949"
                inactive-color="#13ce66" active-text="锁定" inactive-text="启用">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="头像" prop="avatar">
              <el-upload class="avatar-uploader" action="http://localhost:30593/sys/file/uploadimg"
                :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                :headers="uploadHeader">
                <img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <form-footer-toolbar class="text-right">
      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
    </form-footer-toolbar>

  </div>

</template>
<script>

import store from '@/store';
import PageHeader from '@/layout/components/PageHeader';
import FormFooterToolbar from '@/layout/components/FormFooterToolbar';
import { getModel, saveModel } from '@/service/manager.js';
export default {
  components: {
    PageHeader, FormFooterToolbar
  },
  data () {
    return {
      ruleForm: {
        id: 0,
        userName: '',
        nickName: '',
        isLock: false,
        password: '',
        avatar: ''
      },
      rules: {
        userName: [
          { required: true, message: '请输入账号（英文+数字）', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        date1: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ]
      },
      uploadHeader: {
        "Authorization": "Bearer " + store.getters.token
      }
    };
  },
  created () {
    console.log(this.$route.managerId);
    if (this.$route.params.managerId) {
      this.ruleForm.id = this.$route.params.managerId;
      this.dataBinding();
    }
  },
  methods: {
    dataBinding () {
      getModel(this.ruleForm.id).then(res => {
        this.ruleForm = res;
        console.log(res);
      });
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          saveModel(this.ruleForm).then(res => {
            if (res === true) {
              this.$router.go(-1);
            }
            else {
              this.$refs.message("保存异常");
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleAvatarSuccess (res) {
      this.ruleForm.avatar = res.data;
    },
    beforeAvatarUpload (file) {
      const isIMG = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isIMG) {
        this.$message.error('上传头像图片只能是 JPG或者PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return isIMG && isLt2M;
    }
  }
};
</script>
<style lang="scss">
</style>